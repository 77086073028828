import { PlaceholderMap } from 'types/planner';

const placeholders: PlaceholderMap = {
  csScience: {
    id: 'csScience',
    name: 'Science requirement',
    modules: new Set([
      'CM1121',
      'CM1131',
      'CM1417',
      'LSM1102',
      'LSM1105',
      'LSM1106',
      'LSM1301',
      'LSM1302',
      'PC1141',
      'PC1142',
      'PC1143',
      'PC1144',
      'PC1221',
      'PC1222',
      'PC1432',
      'MA2213',
      'MA2214',
      'CM1101',
      'CM1111',
      'CM1161',
      'CM1191',
      'CM1401',
      'CM1402',
      'CM1501',
      'CM1502',
      'LSM1303',
      'LSM1306',
      'PC1421',
      'PC1431',
      'PC1433',
      'MA1104',
      'MA2104',
      'MA1104',
      'MA2104',
      'MA2101',
      'MA2108',
      'MA2501',
      'ST2132',
      'ST2137',
    ]),
  },
};

export default placeholders;
